/*================= 5. HOME ==================*/
@import 'header';
@import 'slider';
@import 'footer';

/*=== 1.BANNER BOTTOM ===*/
.banner-bottom-inner{
	padding:30px 0;
	background-color:$white-color;
	position:relative;
	z-index:500;
	width: 100%;
    float: left;
    @include mobile {
		top:-30px;
	}
    @include tablet {
		top:-75px;
	}
	.col-sm-4:nth-child(2){
		.colContent{
			border-top: 1px solid #dedede;
	    	border-bottom: 1px solid #dedede;
	    	padding-top:25px;
	    	padding-bottom:25px;
	    	margin-top:25px;
	    	margin-bottom:25px;
	    	@include tablet {
				border-left: 1px solid #dedede;
	    		border-right: 1px solid #dedede;
	    		border-top: none;
	    		border-bottom: none;
	    		padding-top:0;
		    	padding-bottom:0;
		    	margin-top:0;
		    	margin-bottom:0;
			}
		}
	}
	.colContent{
		@include tablet {
    		padding-left:15px;
	    	padding-right:15px;
		}
	}
	@include box-shadow(0, 8px, 9px, rgba($black-color, .07) );
	.media-left {
	    width: 76px;
	    height: 76px;
	    padding: 6px;
	    margin-right: 20px;
	    display: inline-block;
	    float: left;
	    @include tablet {
    		width: 56px;
		    height: 56px;
		    margin-right: 15px;
		}
		@include desktops {
    		width: 76px;
		    height: 76px;
		    margin-right: 20px;
		}
	    i {
		    width: 54px;
		    height: 54px;
		    line-height: 54px;
		    text-align: center;
		    font-size: 54px;
		    color: $primary-color;
		    @include tablet {
	    		width: 34px;
			    height: 34px;
			    line-height: 34px;
			    font-size: 34px;
			}
			@include desktops {
	    		width: 54px;
			    height: 54px;
			    line-height: 54px;
			    font-size: 54px;
			}
		}
	}
	.media-body {
		width: auto;
    	display: inherit;
		> .media-heading {
			margin-bottom: 20px;
		    overflow: hidden;
		}
		p{
			margin-bottom: 0;
		}
	}
}

/*=== 1.WHITE SECTION ===*/
.padding-top{
	padding-top:25px;
}
.lawInfo{
	span{
		font-family: 'Libre Baskerville', serif;
		font-size: 15px;
		font-weight: 700;
		text-transform: uppercase;
		margin-bottom: 7px;
		display: block;
	}
	ul li {
		font-size: 13px;
	    margin-bottom: 10px;
	     i {
		    margin-right: 10px;
		}
	}
}
.info-img{
	margin:50px 0;
	@include tablet {
		margin: 0;
	}
	img{
		width: 100%;
	}
}
.progress-wrapper{
	padding:40px 25px;
	background-color:#f5f5f5;
	width: 100%;
    float: left;
}
.progress-bar-container {
    width: 100%;
    height: 40px;
    overflow: hidden;
    background-color: #dedede;
}
.progress-bar {
    height: 100%;
    padding: 10px;
    background: #dedede;
    box-shadow: none;
}
.progress-bar-container.small {
    height: 8px;
}
.progress-bar-inner{
	position:relative;
	background-color: $white-color;
	padding:2px;
    border:1px solid #dedede;
    margin: 5px 0 20px;
    width: 100%;
    float: left;
    &:last-child{
    	 margin:5px 0 0;
    }
}
.progress-bar-container.title-up:not(:first-child) {
    margin-top: 40px;
}
.progress-bar-container.color .progress-bar {
    background-color: $primary-color;
}
.progress-bar-container.small .progress-bar {
    padding: 0px;
}
.progress-title {
    font-size: 13px;
    text-align: left;
}
.progress-bar-container.small .progress-title {
    margin-left: 0;
    margin-top: -20px;
}
.progress-bar-container.title-up .progress-title {
    color: #666666;
    margin-top: -25px;
}
.progress-bar-container.title-up .progress-bar .progress-number,
.progress-bar-container.title-up .progress-bar .progress-type {
    color: #666666;
    margin-top: -25px;
    position: absolute;
    right: 0;
    float: right;
}
.progress-bar-container.title-up .progress-bar .progress-number {
    right: 12px;
}

/*=== 1.DARK SECTION ===*/
.full-width-section {
	position:relative;
	.half-width{
		width:50%;
		height: 370px;
		@include desktops {
			height: 437px;
		}
	}
	.half-width-left{
		float:left;
		background:url("../img/home/home-img1.jpg") no-repeat;
		-webkit-background-size: cover;
	    -moz-background-size: cover;
	    -o-background-size: cover;
	    background-size: cover;
	    background-position: right;
		img{
			height: 100%;
		}
	}
	.half-width-right{
		float:right;
		background-color:#f5f5f5;
		.free-request{
			padding:30px 0 0 30px;
			width: 75%;
			@include desktops {
				padding:70px 0 0 50px;
				width: 78%;
			}
		}
	}
}
.free-request{
	padding:45px 25px;
	background-color:#f5f5f5;
	i{
		font-size: 54px;
		color: $primary-color;
		margin-bottom: 25px;
		display: inline-block;
	}
	h2{
		font-size: 23px;
		@include desktops {
			font-size: 28px;
		}
	}
	p{
		@include desktops {
			margin-bottom: 40px;
		}
	}
	.btn{
		padding-left:25px;
		padding-right:25px;
	}
}

/*=== 1.WHITE SECTION ===*/
.gallery-img{
	figure{
		position: relative;
		margin-bottom: 30px;
		img{
			width: 100%;
		}
	}
	.overlay{
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
		@include opacity(1);
		.overlayInfo{
			position: absolute;
			left: 0;
			top: 30%;
			width: 100%;
			padding:0 15px;
			@include tablet {
				left: 50%;
				top: 62%;
				@include transition(all 0.3s ease-in-out);
				@include transform (translate(-50%, -30%));
			}
			@include desktops {
				padding:0 30px;
			}
			i{
				font-size: 40px;
				margin-bottom: 10px;
			    color: #dca44d;
					display: inline-block;
			    @include mobile {
					font-size: 54px;
				    margin-bottom: 20px;
				}
				@include tablet {
					font-size: 40px;
				    margin-bottom: 5px;
				}
			    @include large-desktops {
					font-size: 54px;
				    margin-bottom: 20px;
				}
			}
			h4{
				color: #fefefe;
				margin-bottom: 10px;
				@include mobile {
				    margin-bottom: 20px;
				}
				@include tablet {
				    margin-bottom: 10px;
				}
			    @include large-desktops {
				    margin-bottom: 20px;
				}
				a{
					color: #fefefe;
				}
			}
			p{
				color: rgba($white-color,0.8);
				font-size: 11px;
				line-height: 18px;
				margin-bottom: 15px;
				@include mobile {
					font-size: 13px;
					line-height: 23px;
    				margin-bottom: 25px;
				}
				@include tablet {
					@include opacity(0);
				    font-size: 11px;
					line-height: 18px;
    				margin-bottom: 15px;
    				@include transition(opacity 0.3s ease-in-out);
				}
			    @include large-desktops {
				    font-size: 13px;
					line-height: 23px;
    				margin-bottom: 25px;
				}
			}
			.btn{
				display: none;
				@include opacity(0);
				padding-left:25px;
				padding-right:25px;
				@include tablet {
					@include transition(opacity 0.3s ease-in-out);
				    display: inline-block;
				}
			}
		}
	}
	&:hover{
		.overlay{
			.overlayInfo{
				@include tablet {
					@include transform (translate(-50%, -62%));
				}
				p{
					@include tablet {
						@include transition(opacity 0.3s ease-in-out);
						-webkit-transition-delay:0.2s;
	 					transition-delay:0.2s;
						@include opacity(1);
					}
				}
				.btn{
					@include transition(opacity 0.3s ease-in-out);
					-webkit-transition-delay:0.2s;
 					transition-delay:0.2s;
					@include opacity(1);
				}
			}
		}
	}
}

/*=== 1.WHITE SECTION ===*/
.process-content{
	padding: 80px 0;
	@include tablet{
		padding: 100px 0;
	}
	.col-sm-4:nth-child(2){
		margin-top: 30px;
		margin-bottom: 30px;
		@include tablet {
			margin-top: 0;
			margin-bottom: 0;
		}
	}
}
.box{
	width:100%;
	margin: 0 auto;
	@include tablet {
		width:220px;
		height: 275px;
	}
	@include desktops {
		width:270px;
		height: 265px;
	}
	.box-inner{
		height: 100%;
		padding:30px 0;
		i{
			font-size: 40px;
    		margin-bottom: 30px;
    		@include transition(all 0.3s ease-in-out);
		}
		h4{
			margin-bottom: 10px;
		}
		p{
			margin-bottom: 10px;
			height: 47px;
		}
		.btn{
			padding-left:25px;
			padding-right:25px;
			&:hover{
				background-color:$primary-color;
			}
		}
	}
	.box-inner:hover{
		i{
			color: $primary-color;
			@include transition(all 0.2s ease-in-out);
		}
	}
}

/*=== 6.3 COUNT UP ===*/
.countUpSection.paralax{
	color: $white-color;
	padding: 80px 0;
	@include tablet{
		padding: 140px 0;
	}
	.col-sm-3 .counter-inner{
		margin-bottom: 80px;
		@include tablet{
			margin-bottom: 0;
		}
	}
	.col-sm-3:last-child .counter-inner{
		margin-bottom: 0;
	}
	.icon{
	    height: 76px;
	    padding: 6px;
	    margin-right: 15px;
	    display: inline-block;
	    @include tablet{
			height: 66px;
		}
		@include large-desktops{
			height: 76px;
		}
    	float: left;
		i{
		    line-height: 68px;
		    font-size: 68px;
		    color: $primary-color;
		    @include tablet{
				line-height: 58px;
		    	font-size: 58px;
			}
			@include large-desktops{
				line-height: 68px;
		    	font-size: 68px;
			}
		}
	}
	.counter{
		line-height: 50px;
		font-size: 42px;
		display: inline-block;
		margin-bottom: 10px;
		@include tablet{
			font-size: 25px;
			line-height: 28px;
		}
		@include desktops{
			font-size: 34px;
			line-height: 38px;
		}
		@include large-desktops{
			font-size: 42px;
			line-height: 50px;
		}
	}
	span{
		font-size: 42px;
		line-height: 50px;
		@include tablet{
			font-size: 25px;
			line-height: 28px;
		}
		@include desktops{
			font-size: 34px;
			line-height: 38px;
		}
		@include large-desktops{
			font-size: 42px;
			line-height: 50px;
		}
	}
	h5{
		font-family: 'Montserrat', sans-serif;
		text-transform: uppercase;
		font-size: 12px;
		font-weight: 700;
		line-height: 18px;
		color: $white-color;
		@include tablet{
			font-size: 10px;
		}
		@include large-desktops{
			font-size: 12px;
		}
	}
}

/*=== 5.7 TEAM SECTION ===*/
.teamSection{
	.col-xs-12{
		.teamContent{
			margin-bottom: 30px;
			@include tablet{
				margin-bottom: 0;
			}
		}
		&:last-child{
			.teamContent{
				margin-bottom: 0;
			}
		}
	}
}
.teamSection.attorneys{
	.col-xs-12{
		.teamContent{
			margin-bottom: 40px;
			@include tablet{
				margin-bottom: 100px;;
			}
		}
		&:last-child{
			.teamContent{
				margin-bottom: 80px;
			}
		}
	}
}
.teamContent{
	img{
		width: 100%;
	}
	.teamImage {
		position:relative;
		margin-bottom: 30px;
	}
	.overlay {
	    width: 100%;
	    height: 100%;
	    position: absolute;
	    left: 0px;
	    top: 0px;
	    opacity: 0;
	    background:rgba($secondary-color,0.5);
	    @include transition(all 0.3s ease-in-out);
	    a{
	    	display: block;
	    	height: 100%;
	    }
	}
	.list-inline{
		li{
			a{
				color: #666666;
				padding:0 5px;
				&:hover{
					color:$primary-color;
				}
			}
		}
	}
}
.teamInfo{
	padding-bottom:25px;
	border-bottom: 1px solid #dedede;
	h3{
		text-transform: capitalize;
		@include transition(all 0.3s ease-in-out);
		margin-bottom: 12px;
		a{
			color:#222222;
		}
	}
	p{
		font-size: 11px;
		margin-bottom: 10px;
		text-transform: uppercase;
	}
	.teamDetail{
		font-size: 13px;
		margin-bottom: 25px;
		text-transform: none;
	}
}
.teamContent:hover{
	.overlay {
		@include transition(all 0.3s ease-in-out);
	    opacity: 1;
	    a{
	    	display: block;
	    	height: 100%;
	    }
	}
	h3{
		a{
			color:$primary-color;
		}
	}
	.teamInfo{
		@include transition(border-color 0.3s ease-in-out);
		border-color: $primary-color;
	}
}
.teamContent.teamCol{
	h3:hover{
		a{
			color:$primary-color;
		}
	}
	.teamInfo{
		border-bottom:none;
		padding-bottom: 0;
		p{
			margin-bottom: 10px;
			@include tablet{
				margin-bottom: 25px;
			}
		}
	}
}

/*=== 5.11 COMMENT SECTION ===*/
.paralax{
	background-attachment: fixed;
	width: 100%;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	background-position: center center;
	height: auto;
	padding:75px 0;
}
.commentSection{
	.owl-carousel .owl-controls .owl-dot {
	    padding: 0;
	    background-color: transparent;
	    border-radius: 50%;
	    box-shadow: none;
	    span{
	    	width: 13px;
		    height: 13px;
		    margin: 5px;
		    border:2px solid rgba($white-color, 0.6);
		    background-color: transparent;
		    -webkit-border-radius: 30px;
		    -moz-border-radius: 30px;
		    border-radius: 30px;
	    }
	}
	.owl-theme .owl-dots .owl-dot.active span,
	.owl-theme .owl-dots .owl-dot:hover span {
	    background: $primary-color;
	    border:2px solid transparent;
	}
}
.commentContent{
	i{
		font-size: 80px;
		color: $primary-color;
		margin-bottom: 50px;
	}
	p{
		font-size: 14px;
		color: rgba($white-color, 0.6);
		margin-bottom: 40px;
	}
	h3{
		font-size:15px;
		color: $primary-color;
		text-transform: capitalize;
		margin: 0 0 8px 0;
	}
	h4{
		font-size:12px;
		color: rgba($white-color, 0.6);
		margin-bottom: 35px;
	}
}

/*=== NEWS SECTION ===*/
.thumbnail{
	border-radius:0
}
.gridView{
	>.caption{
		margin-top: 25px;
		>.list-unstyled, >.list-inline{
			margin-bottom: 20px;
			li{
				font-size: 13px;
				i{
					margin-right: 8px;
				}
				a{
					color: $text-color;
				}
			}
		}
		h2{
			text-transform: none;
			a{
				color:$title-color;
				&:hover{
					color:$primary-color;
				}
			}
		}
	}
}

/*=== 2.5 BRAND SECTION ===*/
.brandSection{
	padding:55px 0 45px;
	background:#f5f5f5;
	.partnersLogoSlider{
		.slide{
			display: table;
			width: 100%;
			.partnersLogo{
				display: table-cell;
				vertical-align: middle;
				text-align:center !important;
				img{
					width: auto;
					margin: 0 auto;
				}
				@include mobile{
					width: 100%;
				}
				@include tablet{
					width: 270px;
				}
			}
		}
	}
	.owl-nav {
	    opacity: 1;
	}
}
.brandSection .owl-carousel .owl-controls .owl-nav .owl-prev,
.brandSection .owl-carousel .owl-controls .owl-nav .owl-next{
 	width:40px;
 	height:40px;
 	line-height:40px;
 	font-size:24px;
 	-webkit-transition:all 0.4s;
 	-o-transition:all 0.4s;
 	transition:all 0.4s;
 	padding: 0;
    background-color: transparent;
    color: 666666;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    border:2px solid #dedede;
 }
 .brandSection .owl-carousel .owl-controls .owl-nav .owl-prev:before{
 	color: #666666;
 	line-height:40px;
 	content: "\f104";
 	display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
 }
 .brandSection .owl-carousel .owl-controls .owl-nav .owl-next:before{
 	color: #666666;
 	content: "\f105";
 	display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
 	line-height:40px;
 }
 .brandSection .owl-carousel .owl-controls .owl-nav:hover .owl-prev:before,
 .brandSection .owl-carousel .owl-controls .owl-nav:hover .owl-next:before{
 	color: #666666;
 }
 .brandSection .owl-carousel .owl-controls .owl-nav .owl-prev{
 	left:0;
 }
 .brandSection .owl-carousel .owl-controls .owl-nav .owl-next{
 	right:0;
 }
 .brandSection:hover .owl-carousel .owl-controls .owl-nav .owl-prev{
 	left:0;
 	margin-left: 0;
 }
 .brandSection:hover .owl-carousel .owl-controls .owl-nav .owl-next{
 	right:0;
 	margin-right: 0;
 }
 .owl-prev span,
 .owl-next span {
 	opacity: 0;
 }
