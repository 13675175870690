.filter-container{
	padding: 0 0 30px;
}
.isotopeFilters{
	ul.filter{
		> li {
			padding:0;
			margin: 0 5px;
			@include tablet{
				margin: 0 15px;
			}
			a{
				font-family: 'Libre Baskerville', serif;
				padding:0 0 10px;
				font-size: 12px;
				color: #222222;
				text-transform: capitalize;
				font-weight: 700;
				display: inline-block;
				background-color:transparent;
				border-bottom: 2px solid transparent;
				line-height: 20px;
				@include tablet{
					font-size: 15px;
				}
			}
			&.active{
				a{
					background-color: transparent;
					color: $primary-color;
					border-color:$primary-color;
				}
			}
		}
	}
}
.isotopeSelector{
	figure{
		position: relative;
		margin-bottom: 30px;
		box-shadow: none;
	}
	.overlay{
		width: 100%;
		height: 100%;
		background-color: rgba(31,32,52, 0.6);
		position: absolute;
		left: 0;
		top: 0;
		@include opacity(0);
		@include transition(all 0.3s ease-in-out);
		.fancybox-pop{
			width: 100%;
			height: 100%;
			display: block;
			text-align: center;
			i{
				width: 45px;
				height: 45px;
				display: block;
				text-align: center;
				color: $white-color;
				line-height: 45px;
				font-size: 15px;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				@include transition(all 0.3s ease-in-out);
				background-color:$primary-color;
				@include border-radius(50%);
			}
		}
	}
	img{
		width: 100%;
	}
	&:hover{
		.overlay{
			@include opacity(1);
		}
		img {
		    transform: scale(1.2) rotate(2deg);
		}
	}
}
.fancybox-lock{
	.fancybox-overlay{
		background-color: rgba($black-color, .75);
	}
}