/*=== 3. HEADER ===*/
/*=== 3.2 TOP INFO BAR ===*/
.top-info-bar{
	display: block;
	z-index: 100;
	background-color:$secondary-color;
	height:35px;
	position: relative;
  top: 0;
  width: 100%;
}
.social-icon {
	li{
		padding:0;
		a{
			display: inline-block;
			padding:7px 13px;
			&:hover{
				background-color:$primary-color;
				i{
					color: rgba($white-color,1)
				}
			}
			i{
				color: rgba($white-color,0.6)
			}
		}
	}
}
.top-bar-right{
	text-align: right;
	a{
		font-size: 11px;
		color: rgba($white-color,0.8);
		text-transform: uppercase;
		line-height: 35px;
		i{
			margin-left: 5px;
		}
	}
}
/*=== 3. NAVBAR ===*/
.navbar-default{
	background-color: transparent;
	border: none;
	border-radius: 0;
	margin-bottom: 0;
}
.navbar-nav{
    height: 300px;
    overflow-x:hidden;
    background-color: #ffffff;
    @include transition(height 0.3s ease);
    margin-top: 0;
    @include tablet{
    	height: auto;
    	overflow-x:visible;
    	overflow-y:visible;
	}
}
.navbar-collapse {
    box-shadow: none;
    position:relative;
    top: -2px;
    @include tablet{
  	   top: 0;
    }
}
.navbar-brand {
  float: left;
  height: auto;
  padding: 15px;
  width: 160px;
  position:relative;
  z-index: 1000;
  > img{
  	width: 100%;
  }
  @include tablet{
  	height: auto;
  	padding: 30px 15px;
  	width: 234px;
  }
}
.navbar-default .navbar-nav{
	@include tablet{
		background-color: transparent;
	}
	&.navbar-right{
		@include tablet{
			margin-right: 30px;
		}
	}
	>li{
		>a{
			font-family: 'Libre Baskerville', serif;
			text-transform: capitalize;
			font-weight: 700;
			font-size: 15px;
			color: #222222;
			border-bottom:3px solid transparent;
			background-color: transparent;
			&:hover, &:focus{
				color: $primary-color ;
				background-color: transparent;
				@include tablet{
					border-bottom:3px solid $primary-color;
					background-color: $primary-color;
					color: $white-color!important;
				}
			}
			@include tablet{
				font-size: 13px;
				margin: 0;
				padding: 20px 7px 24px;
			}
			@include desktops{
				font-size: 15px;
				margin: 0;
				padding: 20px 12px 24px;
			}
		}
		&.active{
			a{
				background-color: transparent;
				@include opacity(1);
				color: $primary-color;
			}
			>a, >a:hover, >a:focus{
				background-color: transparent;
				color: $primary-color;
				@include tablet{
					background-color: $primary-color;
					color: $white-color!important;
				}
			}
		}
		&.open{
			>a{
				background-color: transparent;
				color: $black-color;
				border-color:transparent;
				@include tablet{
					color: $white-color !important;
					background-color: $primary-color;
					border-bottom:3px solid $primary-color;
				}
				&:hover, &:focus{
					background-color: transparent;
					color: $black-color;
					border-color:transparent;
					@include tablet{
						background-color: $primary-color;
						color: $white-color!important;
						border-color:transparent;
					}
				}
			}
		}
		&.dropdown{
			.dropdown-menu{
				border:none;
				box-shadow: none;
				a{
					padding: 5px 20px;
					margin-bottom: 0;
				}
			}
		}
	}
}
.navbar-nav li.dropdown.megaDropMenu{
	position: static;
	.dropdown-menu{
		margin: -1px auto 0 auto;
		right: 15px;
		margin-bottom: 10px;
		@include tablet{
			left: 0;
			padding: 10px 5px 5px;
			width: 720px;
			margin-bottom: 0;
		}
		@include desktops{
			padding: 15px 15px 5px;
			width: 940px;
		}
		@include large-desktops{
			width: 1140px;
		}
		li:nth-child(2){
			@include tablet{
				border-left:1px solid #dedede;
				border-right:1px solid #dedede;
			}
		}
		.mega-img {
			padding:0;
			a{
				padding:10px 0 0;
				background-color: transparent;
				img{
					width: 100%;
				}
			}
		}
		.list-unstyled{
			width: 100%;
			float: left;
			@include tablet{
				padding-bottom: 15px;
			}
			li{
				a{
					color: #666666;
					font-size: 12px;
					float: left;
					width: 100%;
					background-color: transparent;
					@include tablet{
						padding: 8px 12px;
					}
					&:hover{
						color: $primary-color;
					}
				}
			}
		}
	}
}
.nav-wrapper .navbar-nav  li.open.megaDropMenu,
.nav-wrapper .navbar-nav  li.dropdown.megaDropMenu{
	.dropdown-menu{
		@include tablet {
			width: 100%;
			margin-top: -6px;
		}
	}
}
.nav-wrapper .navbar-nav  li.open,
.nav-wrapper .navbar-nav  li.dropdown{
	.dropdown-menu{
		border-radius: 0;
	    box-shadow: none;
	    border: none;
	    width: 100%;
		left:0;
		top:0;
		right: auto;
		margin-top: -1px;
		padding: 5px 5px 5px 15px;
		@include tablet {
			top:100%;
			min-width: 230px;
			width: auto;
			padding: 15px 0 20px 15px;
			margin-top: -4px;
			border-top: 3px solid $primary-color;
		}
		ul.dropdown-menu{
			top: 100%;
			left: 0;
			margin-top: 0;
			@include tablet {
				border: none;
				top: 0 !important;
				left: 100% !important;
				margin-top: -5px;
				margin-left: -5px;
				border-left:10px solid transparent;
				border-top:3px solid transparent;
			}
		}
	}
	.dropdown-menu.dropdown-menu-right{
		left: auto;
		right: 0;
	}
}
.navbar-nav li.dropdown.singleDrop{
	.dropdown-menu{
		li{
			a{
				color: #666666;
				font-size: 12px;
				float: none;
				width: 100%;
				background-color: transparent ;
				@include tablet{
					padding: 8px 12px;
				}
				&:hover{
					background-color: $white-color;
					color: $primary-color ;
				}
			}
			.dropdown-menu{
				margin-bottom: 0;
				li{
					a{

						@include tablet{
							padding:  8px 12px;
						}
					}
				}
			}
			&.open{
				.dropdown-menu{
					@include tablet{
						border-left:10px solid transparent;
						border-top:3px solid transparent;
					}
				}
			}
		}
	}
}
.dropdown-menu{
	border-radius:0;
}
.dropdown-submenu{
	position:relative;
	a{
		position: relative;
		i{
			padding-left:10px;
			font-size: 9px;
		}
	}
	>.dropdown-menu{
		top:0;
		margin-top: 0 !important;
		margin-left:-1px;
		@include mobile{
			left: 0;
		}
		@include tablet{
			left: 100%;
		}
	}
	&.pull-left{
		float:none;
		>.dropdown-menu{
			left:-100%;
			margin-left:10px;
		}
	}
}
.navbar-toggle{
	margin-top: 20px;
}
.navbar-toggle{
	background-color: $white-color;
}
.navbar-collapse{
	@include tablet{
		border-color: $white-color;
	}
}

/*=== 3.4 LIGHT HEADER ===*/
.nav-wrapper{
	position:relative;
	.header-bg{
		position:relative;
		right:0;
		left:0;
		height:70px;
		top:0;
		z-index:1030;
		@include transition(all 0.3s ease-in-out);
		@include tablet{
			height:120px;
		}
		.header-left{
			background-color:#f5f5f5;
			height:70px;
			@include tablet{
				height:120px;
			}
		}
		.header-right{
			background-color:$white-color;
			height:70px;
			@include tablet{
				height:120px;
			}
		}
	}
	.lightHeader{
		position:absolute;
		right: 0;
	    left: 0;
		z-index:1030;
		top: 0;
		height: 70px;
		@include transition(all 0.3s ease-in-out);
		@include tablet{
			height: 120px;
		}
	}
}
.navbar-default{
	.navbar-toggle{
		background-color: $primary-color;
		border-color: $primary-color;
		margin-top: 17px;
		border-radius:0;
		padding:11px 10px;
		&:focus, &:hover {
			background-color: $primary-color;
			border-color: $primary-color;
		}
		.icon-bar{
			background-color: $white-color ;
		}
	}
}
.searchBox{
	display:inline-block;
	float:right;
	position:absolute;
	top: 21px;
  right: 70px;
	@include tablet{
		top:69px;
		right:15px;
	}
	.dropdown-menu{
		width: 235px;
		background-color:$white-color;
		margin-top: -1px;
		border:none;
		@include box-shadow (0, -4px, 4px, rgba(0,0,0,.05));
		@include mobile{
			width: 365px;
		}
		@include tablet{
			width: 430px;
		}
		.navbar-form{
			margin: 0;
			position:relative;
			border-radius:0;
			padding: 0 15px;
			border:none;
			.form-group{
				width: 100%;
				.form-control{
					width: 100%;
					border:none;
					color: #666666;
					padding-left:35px;
				}
			}
		}
		.btn{
			padding:5px;
			position:absolute;
			top: 5px;
			left: 20px;
			border:0;
			box-shadow:none;
			&:hover, &:hover{
				background: transparent;
			}
			i{
				font-size: 16px;
				color: #666666;
			}
		}
	}
	a {
		color: $white-color;
		padding:0 0 18px;
		text-align: center;
		display: inline-block;
		@include tablet{
			padding:0 0 21px;
		}
		i{
			padding:8px 9px;
			width: 31px;
			height: 31px;
			background-color:$primary-color;
		}

	}
}
.navbar-collapse ul.navbar-nav.in > li.dropdown > a:after{
	position: relative;
	right: -8px;
	content: "\f107";
	display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.navbar-collapse ul.navbar-nav > li.dropdown.open > a:after{
	content: "\f106";
}
.navbar-collapse ul.navbar-nav li.dropdown li.dropdown.open > a:after{
	content: "\f106";
}
@include tablet {
	.navbar-collapse ul.navbar-nav.in > li.dropdown > a:after{
		content: "";
	}
	.navbar-collapse ul.navbar-nav > li.dropdown.open > a:after{
		content: "";
	}
	.navbar-collapse ul.navbar-nav li.dropdown li.dropdown.open > a:after{
		content: "";
	}
}
.navbar-collapse ul.navbar-nav > li.dropdown > a:after{
	position: absolute;
	right: 15px;
	top: 10px;
	content: "\f107";
	display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
	@include tablet {
		content: "";
	}
}
.navbar-collapse ul.navbar-nav li.dropdown li.dropdown > a:after{
	position: absolute;
	right: 15px;
	top: 11px;
	content: "\f107";
	display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
	@include tablet {
		right: 15px;
		top: 11px;
		content: "\f105";
	}
}
.navbar-collapse ul.navbar-nav li.dropdown.open li.dropdown > a:after{
	@include tablet {
		content: "\f105";
	}
}
.topList{
	text-align:right;
	margin-top:26px;
	.list-inline{
		display: inline-block;
		padding-bottom:5px;
		border-bottom:1px solid #dedede;
	}
	li{
		font-size:13px;
		font-weight:400;
		color:rgba($black-color, 0.8);
		margin-right: 25px;
		padding: 0;
		i{
			font-size:13px;
			color:rgba($black-color, 0.8);
			margin-right: 7px;
		}
	}
	li:last-child{
		margin-right: 0;
	}

}
