/*=== 1.COMMON ===*/
.container{
	position:relative;
	width: 100%;
	@include mobile {
		width: 450px;
	}
	@include tablet {
		width: 750px;
	}
	@include desktops {
		width: 970px;
	}
	@include large-desktops {
		width: 1170px;
	}
}
a{
	color: $primary-color;
	text-decoration: none;
	@include transition(all 0.3s ease-in-out);
	&:hover, &:focus{
		outline: 0;
		text-decoration: none;
	}
}
i{
	display: inline-block;
}
i.fa {
    font: normal normal normal 14px/1 FontAwesome;
}
.main-wrapper{
	position: relative;
}
.form-control:focus{
	box-shadow: none;
}
.full-width{
	padding-right: 15px;
	padding-left: 15px;
	margin-right: auto;
	margin-left: auto;
}
.primary-content{
	margin-bottom:50px;
	@include tablet {
		margin-bottom:0;
	}
}
.reverse{
	.primary-content{
		margin-bottom:0;
	}
	.sidebar{
		margin-bottom:50px;
		@include tablet {
			margin-bottom:0;
		}
	}
}
.bb-fixed-header {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    z-index: 999;
}
.nav-wrapper.bb-fixed-header{
	position:fixed;
	right:0;
	left:0;
	top: 0;
	.header-bg{
		@include transition(all 0.3s ease-in-out);
	    top: 0;
	    @include box-shadow(0, 4px, 2px, rgba($black-color, .1) );
		@include tablet{
			height: 80px;
		}
		.header-left{
			@include tablet{
				height: 80px;
			}
		}
		.header-right{
			@include tablet{
				height: 80px;
			}
		}
	}
	.lightHeader{
	    right: 0;
	    left: 0;
	    top: 0;
	    z-index: 1030;
	    height: 70px;
	    @include transition(all 0.3s ease-in-out);
		@include tablet{
			height: 80px;
		}
		.navbar-brand{
			height: auto;
			padding: 15px;
			width: 160px;
			@include tablet{
				height: auto;
				padding:15px;
				width: 190px;
			}
		}
		.navbar-nav.navbar-right {
			@include tablet{
				margin-top: 0;
			}
		}
	    .topList{
	    	display: none;
	    }
	    .searchBox {
		    display: none;
		}
	}
	.navbar-default .navbar-nav.navbar-right {
	    margin-right: 0;
	}
	.navbar-nav  li.open,
	.navbar-nav  li.dropdown{
		.dropdown-menu{
			@include tablet {
				border:1px solid #e0e1e2;
				@include box-shadow(0, 4px, 10px, rgba(0,0,0,0.33));
			}
			ul.dropdown-menu{
				@include tablet {
					margin-left: 1px;
					border-left:1px solid #e0e1e2;
					border-top:3px solid transparent;
				}
			}
		}
	}
}
.bb-fixed-header .navbar-default .navbar-nav > li > a {
    @include tablet {
	    padding: 27px 7px 30px;
		}
		@include desktops {
	    padding: 27px 12px 30px;
		}
}
.main-wrapper{
  background-color:#ffffff;
}
body.bodyColor.default{
  background-color:#f5f5f5;
}
body.bodyColor.pattern-01{
  background-image: url("../img/patterns/001.png");
  background-repeat: repeat;
    background-attachment: fixed;
    background-position: center;
}
body.bodyColor.pattern-02{
  background-image: url("../img/patterns/002.png");
  background-repeat: repeat;
    background-attachment: fixed;
    background-position: center;
}
body.bodyColor.pattern-03{
  background-image: url("../img/patterns/003.png");
  background-repeat: repeat;
    background-attachment: fixed;
    background-position: center;
}
body.bodyColor.pattern-04{
  background-image: url("../img/patterns/004.png");
  background-repeat: repeat;
    background-attachment: fixed;
    background-position: center;
}
body.bodyColor.pattern-06{
  background-image: url("../img/patterns/006.png");
  background-repeat: repeat;
    background-attachment: fixed;
    background-position: center;
}
body.bodyColor.pattern-07{
  background-image: url("../img/patterns/007.png");
  background-repeat: repeat;
    background-attachment: fixed;
    background-position: center;
}
body.bodyColor.pattern-05{
  background-image: url("../img/patterns/005.png");
  background-repeat: repeat;
    background-attachment: fixed;
    background-position: center;
}
body.bodyColor.wrapper {
    margin:0 auto;
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.1);
  @include mobile {
    max-width: 450px;
  }
  @include tablet {
    max-width: 750px;
  }
  @include desktops {
    max-width: 970px;
  }
  @include large-desktops {
    max-width: 1170px;
  }
}
body.bodyColor .header, body.bodyColor .bb-fixed-header {
  @include mobile {
    max-width: 450px;
  }
  @include tablet {
    max-width: 750px;
  }
  @include desktops {
    max-width: 970px;
  }
  @include large-desktops {
    max-width: 1170px;
  }
}
body.bodyColor .bb-fixed-header{
  margin: 0 auto;
}
.navbar .container{
  position:relative;
}
.static .bb-fixed-header{
  display: none;
}
