* {
    outline: 0;
    margin: 0;
    padding: 0;
}
html, * {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body {
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    line-height: 21px;
    color: $text-color;
    font-weight: 400;
}
ul{
	margin:0;
	padding:0;
	li{
		list-style-type:none;
	}
}
p{
  font-size: 12px;
  color: $text-color;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 15px;
  @include mobile {
    font-size: 13px;
    line-height: 23px;
    margin-bottom: 25px;
  }
}
h1, h2, h3, h4, h5{
  font-family: 'Libre Baskerville', serif;
  text-transform: uppercase;
  margin-top: 0;
  font-weight: 700;
  color: $title-color;
}
h2{
    font-size: 19px;
    margin-bottom: 25px;
    line-height: 33px;
    @include tablet{
        font-size: 24px;
    }
}
h3{
   font-size: 15px;
   margin-bottom: 15px;
   @include mobile{
        font-size: 18px;
        margin-bottom: 20px;
    }
    @include tablet {
        font-size: 15px;
        margin-bottom: 15px;
    }
    @include desktops {
       font-size: 18px;
        margin-bottom: 20px;
    }
    a{
        text-transform: capitalize;
        color:#222222;
        &:hover{
            color:$primary-color;
        }
    }
}
h4{
   font-size: 18px;
   margin-bottom: 20px;
   text-transform: capitalize;  
}
h5{
    font-size: 15px;
    text-transform: capitalize;
}
.form-control{
    box-shadow: none;
}