footer{
	background-image: url(../img/home/footer-bg.jpg);
    background-attachment: fixed;
    width: 100%;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: center center;
    padding-bottom:30px;
    @include tablet{
		padding-bottom:55px;
	}
} 

/*=== 4.2 FOOTER TOP ===*/

.footerTop-inner{
	padding:50px 0;
	.list-inline li{
		margin:0 13px;
		>a{
			font-family: 'Libre Baskerville', serif;
			text-transform: capitalize;
			font-weight: 700;
			font-size: 12px;
			color: $white-color;
			padding:0;
			&:hover{
				color: $primary-color ;
				background: transparent;
			}
			@include tablet{
				font-size: 13px;
			}
			@include desktops{
				font-size: 15px;
			}
		}
		&.active{
			a{
				color: $primary-color ;
			}
		}
	}
}

/*=== 4.2 FOOTER MIDDLE ===*/
.footerMiddle{
	border-bottom: 1px solid rgba($white-color, 0.1);
	border-top: 1px solid rgba($white-color, 0.1);
	@include tablet {
		border:none;
	}
}
.footerMiddle-inner{
	padding:50px 0;
	@include tablet {
		border-bottom: 1px solid rgba($white-color, 0.1);
		border-top: 1px solid rgba($white-color, 0.1);
	}
	
	.col-sm-3{
		margin-bottom: 40px;
		 @include tablet {
			margin-bottom: 0;
		}
	}
	.footerTitle h4{
		margin-bottom: 7px;
	}
}
.footerInfo{
	h2{
		font-family: 'Montserrat', sans-serif;
		margin-bottom: 5px;
		color: $white-color;
	}
	h4{
		font-size: 12px;
		font-weight: 700;
	    margin-bottom: 0;
	    text-transform: uppercase;
	    color: $primary-color ;
	}
	.input-group {
	    width: 100%;
	}
	.form-control {
	    background-color: transparent;
	    border: 0;
	    border-bottom:1px solid rgba($white-color, 0.5);
	    color: rgba($white-color, 0.3);
	    height: 40px;
	    width: 100%;
	    border-radius:0;
	    padding-left:0;
	    padding-top:0;
	    margin-bottom: 25px;
	    @include tablet {
			width: 65%;
			margin-bottom: 0;
		}
		&:focus{
			border-color: $primary-color;
			@include transition(border-color 0.3s ease-in-out);
		}
	}
	.input-group .input-group-addon {
		@include transition(all 0.3s ease-in-out);
	    background-color:transparent;
	    border: 2px solid rgba($white-color, 0.5);
	    width: 140px;
	    height: 40px;
	    color: $white-color ;
	    font-size: 12px;
		font-weight: 700;
	    text-transform: uppercase;
	    border-radius:0;
	    @include tablet {
	    	float: right;
			width: 30%;
		}
		&:hover{
			background-color:$primary-color;;
	    	border-color: $primary-color;
		}
	}
}
.footerTitle h4{
	font-size: 13px;
	color: $white-color;
	text-transform: uppercase;
}

/*=== 4.2 FOOTER BOTTOM ===*/
.footerBottom-inner{
	padding:50px 0;
	color:rgba($white-color, 0.7);
	.col-sm-3{
		margin-top: 40px;
		 @include tablet {
			margin-top: 0;
		}
	}
	.media-left{
	    display: inline-block;
	    width: 74px;
	    float: left;
	}
	.media-body{
		p{
			color:rgba($white-color, 0.7);
			margin-bottom: 20px;
		}
		a{
			text-transform: uppercase;
			color: $primary-color;
		}
	}
	.footerTitle h4:after{
		content: "";
	    width: 15px;
	    height: 3px;
	    background-color:$white-color;
	    display: inline-block;
	    vertical-align: middle;
	    margin-left: 15px;
	}
	.footerInfo{
		address{
			margin-bottom: 0;
		}
		p{
			margin-bottom: 10px;
			color:rgba($white-color, 0.7);
		}
		span{
			display: block;
			margin-bottom: 3px;
		}
		a{
			color:rgba($white-color, 0.7);
			text-decoration: underline !important;
		}
		a:hover{
			color: $primary-color ;
		}
	}
	
}

/*=== 4.2 COPY RIGHT ===*/
.copyRight{
	border: 1px solid rgba($white-color, 0.2);
	background-color: rgba($white-color, 0.02);
	padding:20px 15px;
	text-align: center;
	@include tablet{
		text-align: justify;
		padding: 10px 20px 10px 30px;
	}
	.list-inline{
		@include tablet{
			text-align: right;
		}
		li{
			a{
				color: $white-color;
    			padding: 0 5px;
    			&:hover{
    				color: $primary-color;
    			}
				
			}
		}
	}
}
.copyRightText{
	p{
		margin-bottom: 20px;
		color:rgba($white-color, 0.4);
		@include tablet{
			margin-bottom: 0;
		}
		a{
			color:rgba($white-color, 0.4);
			text-decoration: underline !important;
			&:hover{
				color: $primary-color;
			}
		}
	}
}
