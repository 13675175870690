/*=== 1.MAIN SLIDER ===*/
.main-slider{
	width:100%;
	overflow:hidden;
}
 .main-slider .slide{
 	display:block;
 	width:100%;
 	height:300px;
 	background-position:center;
 	background-repeat:no-repeat;
 	background-size:cover;
 	@include mobile{
		height:510px;
	}
 	@include desktops{
		height:680px;
	}
 }
 .main-slider .owl-carousel .owl-controls .owl-nav{
	display:none ;
	height:0;
	@include tablet{
		display:block;
	}
}
 .main-slider .slide>.container{
 	position:relative;
 	display:table;
 	max-width:1170px;
 	height:100%;
 }
 .main-slider .slide h1,
 .main-slider .slide h2,
 .main-slider .slide h3,
 .main-slider .slide h4,
 .main-slider .slide h5,
 .main-slider .slide h6,
 .main-slider .slide p,
 .main-slider .slide .h1,
 .main-slider .slide .h2,
 .main-slider .slide .h3,
 .main-slider .slide .h4,
 .main-slider .slide .h5,
 .main-slider .slide .h6{
 	display:block;
 	margin-bottom:0;
 }
 .main-slider .owl-carousel .owl-controls .owl-nav .owl-prev,
 .main-slider .owl-carousel .owl-controls .owl-nav .owl-next{
 	width:40px;
 	height:90px;
 	line-height:90px;
 	font-size:24px;
 	-webkit-transition:all 0.4s;
 	-o-transition:all 0.4s;
 	transition:all 0.4s;
 }
 .main-slider .owl-carousel .owl-controls .owl-nav .owl-prev,
 .main-slider .owl-carousel .owl-controls .owl-nav .owl-next,
 .main-slider .owl-carousel .owl-controls .owl-dot {
    padding: 0;
    background-color: rgba($black-color,0.3);
    color: $white-color;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
}
 .main-slider .owl-carousel .owl-controls .owl-nav .owl-prev:before{
 	line-height:90px;
 	content: "\f104";
 	display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
 	line-height:90px;
 }
 .main-slider .owl-carousel .owl-controls .owl-nav .owl-next:before{
 	content: "\f105";
 	display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
 	line-height:90px;
 }
 .main-slider .owl-carousel .owl-controls .owl-nav .owl-prev{
 	left:-120px;
 }
 .main-slider .owl-carousel .owl-controls .owl-nav .owl-next{
 	right:-120px;
 }
 .main-slider:hover .owl-carousel .owl-controls .owl-nav .owl-prev{
 	left:60px;
 	margin-left: 30px;
 }
 .main-slider:hover .owl-carousel .owl-controls .owl-nav .owl-next{
 	right:60px;
 	margin-right: 30px;
 }
 .main-slider .slider-layer{
 	position:absolute;
 }
 .main-slider .owl-item .from-top,
 .main-slider .owl-item .from-bottom,
 .main-slider .owl-item .from-left,
 .main-slider .owl-item .from-right,
 .main-slider .owl-item .scale-up,
 .main-slider .owl-item .scale-down{
 	opacity:0;
 	-webkit-transition:all 0.5s 0.3s ease-in-out;
 	-o-transition:all 0.5s 0.3s ease-in-out;
 	transition:all 0.5s 0.3s ease-in-out;
 	-webkit-backface-visibility:hidden;
 	-moz-backface-visibility:hidden;
 	backface-visibility:hidden;
 }
 .main-slider .owl-item .from-top{
 	-webkit-transform:translateY(-45px);
 	-moz-transform:translateY(-45px);
 	-ms-transform:translateY(-45px);
 	-o-transform:translateY(-45px);
 	transform:translateY(-45px);
 }
 .main-slider .owl-item .from-bottom{
 	-webkit-transform:translateY(45px);
 	-moz-transform:translateY(45px);
 	-ms-transform:translateY(45px);
 	-o-transform:translateY(45px);
 	transform:translateY(45px);
 }
 .main-slider .owl-item .from-left{
 	-webkit-transform:translateX(-45px);
 	-moz-transform:translateX(-45px);
 	-ms-transform:translateX(-45px);
 	-o-transform:translateX(-45px);
 	transform:translateX(-45px);
}
 .main-slider .owl-item .from-right{
 	-webkit-transform:translateX(45px);
 	-moz-transform:translateX(45px);
 	-ms-transform:translateX(45px);
 	-o-transform:translateX(45px);
 	transform:translateX(45px);
}
 .main-slider .owl-item .scale-up{
 	-webkit-transform:scale(0.8);
 	-moz-transform:scale(0.8);
 	-ms-transform:scale(0.8);
 	-o-transform:scale(0.8);
 	transform:scale(0.8);
}
 .main-slider .owl-item .scale-down{
 	-webkit-transform:scale(1.2);
 	-moz-transform:scale(1.2);
 	-ms-transform:scale(1.2);
 	-o-transform:scale(1.2);
 	transform:scale(1.2);
}
 .main-slider .owl-item .delay-1{
 	-webkit-transition-delay:0.5s;
 	transition-delay:0.5s;
}
 .main-slider .owl-item .delay-2{
 	-webkit-transition-delay:0.7s;
 	transition-delay:0.7s;
}
 .main-slider .owl-item .delay-3{
 	-webkit-transition-delay:0.9s;
 	transition-delay:0.9s;
 }
 .main-slider .owl-item .delay-4{
 	-webkit-transition-delay:1.1s;
 	transition-delay:1.1s;
 }
 .main-slider .owl-item.active .from-top,
 .main-slider .owl-item.active .from-bottom{
 	opacity:1;
 	-webkit-transform:translateY(0);
 	-moz-transform:translateY(0);
 	-ms-transform:translateY(0);
 	-o-transform:translateY(0);
 	transform:translateY(0);
 }
 .main-slider .owl-item.active .from-left,
 .main-slider .owl-item.active .from-right{
 	opacity:1;
 	-webkit-transform:translateX(0);
 	-moz-transform:translateX(0);
 	-ms-transform:translateX(0);
 	-o-transform:translateX(0);
 	transform:translateX(0);
 }
 .main-slider .owl-item.active .scale-up,
 .main-slider .owl-item.active .scale-down{
 	opacity:1;
 	-webkit-transform:scale(1);
 	-moz-transform:scale(1);
 	-ms-transform:scale(1);
 	-o-transform:scale(1);
 	transform:scale(1);
 }
.main-slider .owl-controls{
 	margin: 0;
 	.owl-dots{
 		display: none !important;
 	}
 }
.slide-inner1{
	.h3{
		font-family: 'Libre Baskerville', serif;
		font-size: 12px;
		line-height: 16px;
		color: $primary-color;
		font-weight: 700;
		text-transform: uppercase;
		margin-top:10%;
		@include mobile{
			font-size: 16px;
			line-height: 20px;
			margin-top:16%;
		}
		@include tablet{
			font-size: 20px;
			line-height: 25px;
		}
		@include desktops{
			font-size: 24px;
			line-height: 30px;
		}
	}
	.h1{
		font-family: 'Libre Baskerville', serif;
		font-size: 25px;
		line-height: 25px;
		color: $white-color;
		font-weight: 400;
		text-transform: uppercase;
		margin: 0;
		@include mobile{
			font-size: 35px;
			line-height: 35px;
		}
		@include tablet{
			font-size: 50px;
			line-height: 50px;
			margin: 0 0 10px;
		}
		@include desktops{
			font-size: 80px;
			line-height: 80px;
			margin: 0 0 30px;
		}
	}
	.h4{
		font-size: 11px;
		line-height: 20px;
		color: $white-color;
		margin-bottom: 10px ;
		margin-top: 0 ;
		@include mobile{
			font-size: 14px;
			line-height: 25px;
			margin-bottom: 15px;
		}
		@include desktops{
			font-size: 17px;
			line-height: 26px;
			margin-bottom: 40px;
		}
	}
}
.main-slider .slide a{
	width: 170px;
	text-align: center;
	display:block;
	margin: 0 auto;
	@include tablet{
		width: 190px;
		display:inline-block;
	}
	@include desktops{
		width: 210px;
	}
	&.first-btn{
		margin-bottom: 10px;
		@include tablet{
			margin-right: 15px;
			margin-bottom: 0;
		}
	}
}
.main-slider .slide-inner2{
	background:url('../img/home/slider-inner1.png') no-repeat;
	background-size:100%;
	width:290px;
	height:200px;
	top:50px;
	padding:10px;
	position:absolute;
	left:15px;
	z-index:99999999;
	border-top:4px solid $primary-color;
	@include mobile{
		width:363px;
		height:240px;
		top:80px;
		padding:10px 30px 10px 10px;
	}
	@include tablet{
		width:450px;
		height:295px;
		top:100px;
		padding:20px 30px 20px 20px;
	}
	@include desktops{
		width:562px;
		height:365px;
		top:120px;
		padding:30px 40px;
	}
	@include large-desktops{
		width:582px;
		height:380px;
		top:130px;
		padding:40px 50px;
	}
}
.main-slider .common-inner{
	.h3{
		font-family: 'Libre Baskerville', serif;
		font-size: 12px;
		line-height: 14px;
		color: $primary-color;
		font-weight: 700;
		text-transform: uppercase;
		margin: 0;
		@include tablet{
			font-size: 13px;
			line-height: 16px;
		}
		@include large-desktops{
			font-size: 16px;
			line-height: 20px;
		}
	}
	.h2{
		font-family: 'Libre Baskerville', serif;
		font-size: 20px;
		line-height: 20px;
		color: $white-color;
		font-weight: 700;
		text-transform: uppercase;
		margin:-12px 0 0;
		@include mobile{
			margin: 0;
		}
		@include tablet{
			font-size: 25px;
			line-height: 25px;
		}
		@include desktops{
			font-size: 30px;
			line-height: 30px;
		}
		@include large-desktops{
			font-size: 40px;
			line-height: 40px;
		}
	}
	.h4{
		font-size: 11px;
		line-height: 17px;
		color: $white-color;
		margin:-12px 0 0;
		@include mobile{
			margin: 0;
		}
		@include tablet{
			font-size: 12px;
			line-height: 20px;
		}
		@include desktops{
			font-size: 14px;
			line-height: 23px;
		}
	}
	a{
		margin:-12px 0 0;
		@include tablet{
			margin: 0;
		}
	}
}
.main-slider .common-inner.slide-inner3 .h3{
	color: $white-color;
}
.main-slider .slide-inner3{
	position:absolute;
	top:50px;
	right:15px;
	width:290px;
	@include mobile{
		width:363px;
		top:80px;
	}
	@include tablet{
		width:400px;
		top:100px;
	}
	@include desktops{
		width:470px;
		top:150px;
	}
	.h3{
		font-size: 12px;
		line-height: 14px;
		padding:10px 15px;
		color: $white-color;
		background-color:$primary-color;
		display: inline-block !important;
		@include tablet{
			font-size: 16px;
			line-height: 20px;
			padding:15px 20px;
		}
	}
	.h2{
		background-color:#1f2034;
		padding:10px 15px;
		margin-top: 7px;
		@include tablet{
			line-height: 40px;
			padding:15px 20px;
		}
	}
	.h4{
		color: #222222;
	}
	a{
		color: #666666;
	}
}
