/*=== 1.WHITE SECTION ===*/
.practice-img{
	figure{
		margin-bottom: 30px;
		img{
			width: 100%;
			margin-bottom: 30px;
		}
	}
	.practiceInfo{
		h4{
			margin-bottom: 15px;
		    @include desktops {
			    margin-bottom: 20px;
			}
			a{
				color: $title-color;
			}
		}
		p{
			color: rgba($text-color,0.7);
		}
		.btn{
			padding-left:25px;
			padding-right:25px;
			color: $text-color;
			border-color: #dedede;
		}
	}
}
.title-img{
	margin-bottom:30px;
}
.inner-img{
	margin-bottom:30px;
	@include tablet{
		margin-bottom:0;
	}
}
