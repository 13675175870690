/*=== 2. UI KIT ===*/
/*=== 2.1 BUTTON ===*/
.btn{
	font-family: 'Montserrat', sans-serif;
    font-size: 11px;
    line-height: 11px;
    padding:12px 0;
    color: $white-color;
    font-weight: 700;
    border:none;
    text-transform: uppercase;
    background-color:transparent;
    border-radius:0;
    @include tablet{
		padding:13px 0;
		font-size: 12px;
	}
	@include desktops{
		padding:15px 0;
	}
    &:focus, &.active, &:active, &:hover{
		border: none;
		outline: 0;
		color: $white-color;
	}
}
.waves-button, .waves-button:hover,
.waves-button:visited, .waves-button-input{
    font-size: 11px;
    line-height: 11px;
    color: $white-color;
    box-shadow:none;
    @include tablet{
		font-size: 12px;
	}
}
.btn-primary{
	background-color: $primary-color;
	@include transition(all 0.3s ease-in-out);
	>i{
		margin-right: 10px;
	}
	&:hover{
		background-color: $primary-color;
	}
	&:focus, &.active, &:active{
		background-color: $primary-color;
	}
	&.btn-Full{
		float: left;
		width: 100%;
	}
}
.btn-secondary{
	@include transition(all 0.3s ease-in-out);
	background-color: $secondary-color;
	>i{
		margin-right: 10px;
	}
	&:hover{
		background-color: $primary-color;
	}
	&:focus, &.active, &:active{
		background-color: $primary-color;
	}
}
.btn-border{
	border: 2px solid $white-color;
	padding:10px 0;
	@include tablet{
		padding:11px 0;
	}
	@include desktops{
		padding:13px 0;
	}
	&:hover,&:focus{
		border: 2px solid $white-color;
	}
	>i{
		margin-right: 10px;
	}
}
.btn-primary.active.focus,
.btn-primary.active:focus,
.btn-primary.active:hover,
.btn-primary:active.focus,
.btn-primary:active:focus,
.btn-primary:active:hover,
.open>.dropdown-toggle.btn-primary.focus,
.open>.dropdown-toggle.btn-primary:focus,
.open>.dropdown-toggle.btn-primary:hover{
	border: none;
	background-color: $primary-color;
	outline: 0;
}
.empty{
	text-indent:-999px;
}
.backToTop{
	position: fixed;
	bottom: 20px;
	right: 20px;
	width: 50px;
	height: 50px;
	text-align: center;
	line-height: 45px;
	background-color: $primary-color;
	@include border-radius(13px);
	color: $white-color;
	display: block;
	font-size: 16px;
	@include opacity(0);
	@include transition(all 0.3s ease-in-out);
	&:hover, &:focus{
		color: $white-color;
	}
}
.squareBtn, .fullRoundBtn, .roundBtn{
  margin: 0 0 30px 0;
  text-align: center;
}
.btn-common{
  color: $title-color;
  border: 1px solid rgba($black-color,0.1);
  padding-right:38px;
  padding-left:38px;
  background-color: transparent;
  border-radius: 0;
  letter-spacing: 1px;
  margin: 0 auto 20px auto;
  &:focus, &.active, &:active, &:hover{
    color: $title-color;
    border: 1px solid rgba($black-color,0.1);
  }
  @include tablet{
    margin: 0;
    padding-right:14px;
  	padding-left:14px;
  }
  @include desktops{
    padding-right:38px;
  	padding-left:38px;
  }
}
.paralax .btn-common{
  border: 1px solid rgba($white-color,0.3);
  color: $white-color;
  &:focus, &.active, &:active, &:hover{
    color: $white-color;
  }
}
.paralax .btn-gray{
  color: $title-color;
  background-color: #f5f5f5;
  &:focus, &.active, &:active, &:hover{
  	color: $title-color;
    background-color: #f5f5f5;
  }
}
.btn-theme{
  background-color: $primary-color;
  border-color: $primary-color;
  color: $white-color;
  &:focus, &.active, &:active, &:hover{
    background-color: $primary-color;
    border-color: $primary-color;
    color: $white-color;
  }
}
.btn-gray{
  color: $title-color;
  background-color: #f5f5f5;
  &:focus, &.active, &:active, &:hover{
  	color: $title-color;
    background-color: #f5f5f5;
  }
}
.btn-dark{
  background-color: $secondary-color;
  border-color: $secondary-color;
  color: $white-color;
  &:focus, &.active, &:active, &:hover{
    background-color: $secondary-color;
    border-color: $secondary-color;
    color: $white-color;
  }
}
.btn-full-round{
  border-radius: 25px;
}
.btn-round{
  border-radius: 4px;
}

/*=== 2.14 ALERT ===*/
.alertArea{
  padding:70px 0;
}
.alert-common{
  color: $white-color;
  font-size: 14px;
  padding: 11px 15px;
  margin: 0 auto 30px auto;
  width: 95%;
  border: none;
  border-radius: 0;
  span{
    font-weight: 600;
  }
  &.alert-success{
    background-color: #6fc96f;
  }
  &.alert-info{
    background-color: #4da5c9;
  }
  &.alert-warning{
    background-color: #e6b048;
  }
  &.alert-danger{
    background-color: #e26857;
  }
}

/*=== 2.2 SECTION TITLE ===*/
.commonSection{
	padding: 80px 0;
	@include tablet{
		padding: 100px 0;
	}
}
.padding-topLarge{
	padding-top:60px;
	@include tablet{
		padding-top: 80px;
	}
}
.padding-bottom{
	padding-bottom:60px;
	@include tablet{
		padding-bottom: 80px;
	}
}
.whiteSection{
	background-color: $white-color;
}
.lighter-section{
	background-color:#f5f5f5;
}
.sectionTitle{
	margin-bottom:45px;
	h2{
		&:before, &:after{
			content: "";
			width: 15px;
			height: 3px;
			background-color:$primary-color;
			display: inline-block;
		    vertical-align: middle;
		}
		&:before{
			margin-right: 15px;
		}
		&:after{
			margin-left: 15px;
		}
	}
	&.sectionTitleWhite{
		h2{
			color: $white-color;
		}
	}
	p{
		font-size: 14px;
		margin-bottom: 0;
	}
	&.text-left{
		margin-bottom:0;
		h2{
			&:before{
				display: none;
				margin-right: 0;
			}

		}
		p{
			margin-bottom: 25px;
			font-size: 13px;
		}
	}
}
.bottom-button{
	margin-top:20px;
	.btn{
		padding-right:25px;
		padding-left:25px;
	}
}
.border-all{
	border:1px solid #dedede;
}
.border-bottom{
	border-bottom:1px solid #dedede;
}
.border-top{
	border-top:1px solid #dedede;
}
.pageTitleSection{
	height: 160px;
    width: 100%;
    padding: 65px 0;
    background-color:#1f2034;
	.pageTitleInfo{
		h2{
			font-size: 24px;
			color: $white-color;
			margin: 0 0 5px;
			line-height: 28px;
			display: inline-block;
		}
		.breadcrumb{
			background-color: transparent;
			margin: 0;
			li{
				color: $white-color;
				font-size: 16px;
				a{
					color: $white-color;
					font-size: 15px;
				}
			}
			li + li::before {
			    content: " \276F\00a0 ";
			}
		}
	}
	.pageTitleInfo.align-right{
		text-align: center;
		@include tablet {
			text-align: justify;
			margin-top: 20px;
		}
		.breadcrumb{
			float: none;
			@include tablet {
				float: right;
			}
			li a{
				@include tablet {
					padding:0;
				}
			}
		}
	}
}
.pageTitleSection.paralax-bg{
	height: 200px;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: center center;
}
/*=== 2.3 FORM ===*/

.formPanel{
	border: 0;
	.panel-heading {
	    padding: 0;
	    border-bottom: 1px solid transparent;
	    border-top-left-radius: 0;
	    border-top-right-radius: 0;
	    position:relative;
	}
	.panel-title{
		font-size: 24px;
		margin-bottom: 25px;
		color: #222222;
	}
	p{
		font-size: 14px;
		margin-bottom: 35px;
	}
}
.panel-body{
	border-top: 0;
	padding:0;
}
.formField{
	margin-bottom:10px;
	.form-control{
		height: 44px;
		color: #666666;;
		border-color: #dedede;
		box-shadow: none;
		font-size: 13px;
		border-radius:0;
		&:focus{
			border-color: $primary-color;
		}
	}
	textarea.form-control{
		height: 130px;
	}
	.btn-secondary{
		padding-right:25px;
		padding-left:25px;
		margin-top: 50px;
	}
}
.formField-left{
	width:100%;
	@include tablet {
		width:49%;
		float:left;
	}
}
.formField-right{
	width:100%;
	@include tablet {
		width:49%;
		float:right;
	}
}

/*=== 2.5 COLLAPSE ===*/
.navbar-collapse.content-collapse {
    padding-right: 0;
    padding-left: 0;
    margin-top: 10px;
    display: block;
    ul.navbar-nav{
    	float: none;
	    height: auto;
	    overflow-x: visible;
	    background-color: #ffffff;
	    -webkit-transition: height 0.3s ease;
	    -moz-transition: height 0.3s ease;
	    -ms-transition: height 0.3s ease;
	    -o-transition: height 0.3s ease;
	    transition: height 0.3s ease;
	    margin: 0;
    	li{
    		border:1px solid #dedede;
    		display: inline-block;
    		width: 100%;
    		float: none;
    		margin-bottom: 10px;
    		a{
    			font-size: 14px;
    			padding:20px 25px;
    			position: relative;
    			font-weight: 700;
  				color: #222222;
  				@include mobile {
  					font-size: 17px;
				}
  				i{
  					position:absolute;
  					right: 25px;
  					font-size: 15px;
  					font-weight: lighter;
  					color: rgba($black-color, 0.6);
  					@include mobile {
	  					font-size: 19px;
					}
  				}
    		}
    		a.collapsed[aria-expanded="true"] {
    			margin-bottom: 0;
			}
    		a.collapsed{
    			margin-bottom: 0;
    		}
    		a, a:hover, a:focus{
    			background: transparent;
    		}
    		p{
    			margin-bottom: 0;
    			margin-top: 25px;
					padding: 0 15px;
    			&:first-child{
    				margin-top: 0;
    			}
    		}
    	}
    }
    .collapse-inner{
    	padding:0 25px 20px 25px ;
    }
}
.collapse-style2 .navbar-collapse.content-collapse {
    ul.navbar-nav{
    	li{
    		border-bottom:none;
    		a{
    			border-bottom:1px solid #dedede;
    			background:#f5f5f5;
    		}
    	}
    }
    .collapse-inner{
    	padding:20px 25px 20px 25px ;
    	border-bottom:1px solid #dedede;
    }
}
.collapse-style3 .navbar-collapse.content-collapse {
    ul.navbar-nav{
    	li{
    		border:none;
    		a{
    			border:1px solid #dedede;
    			background:#f5f5f5;
    		}
    	}
    }
    .collapse-inner{
    	padding:20px 25px 20px 25px ;
    }
}

/*=== 2.6 TABS ===*/
.tabCommon{
	.nav{
		border-bottom: none;
		li{
			display: inline-block;
			a{
				padding: 0 8px;
				height:  35px;
				font-size: 10px;
				line-height: 35px;
				text-transform: uppercase;
				display: inline-block;
				background-color: $white-color;
				color: $text-color;
				border-radius:0;
				border:1px solid #dedede;
				border-right: 1px solid transparent;
				margin: 0;
				@include tablet{
					height:  45px;
					font-size: 12px;
					line-height: 45px;
					padding: 0 25px;
				}
			}
			&:last-child a{
				border-right: 1px solid #dedede;
			}
			&.active{
				a{
					background-color:$white-color;
					color:$primary-color;
					border-bottom:1px solid transparent;
				}
			}
		}
	}
	.tab-content{
		padding: 25px;
		border:1px solid #dedede;
		p{
			margin-bottom: 0;
			margin-top: 25px;
			&:first-child{
				margin-top: 0;
			}
		}
	}
	&.tabLeft{
		.nav{
			@include tablet{
				display: table-cell;
				width: 150px;
			}
			li{
				@include tablet{
					display: block;
					float: none;
				}
				a{
					@include tablet{
						display: block;
						border:1px solid #dedede;
						border-right: 1px solid transparent;
						border-bottom: 1px solid transparent;
					}
				}
				&:last-child a{
					@include tablet{
						border-right: 1px solid transparent;
						border-bottom: 1px solid #dedede;
					}
				}
				&.active{
					a{

					}
				}
			}
		}
		.tab-content{
			display: table-cell;
		}
	}
}
.Tab-style2 .tabCommon{
	.nav{
		li{
			margin-top: -2px;
		}
		li.active a{
			border-top:2px solid $primary-color;
		}
	}
}
/*=== 2.9 MODAL ===*/
.customModal{
	.modal-content{
		border-radius: 0;
		padding:30px;
		position:relative;
		@include tablet{
			padding:75px;
		}
		button.close{
			position:absolute;
			right: 0;
			top: -50px;
			width: 40px;
			height: 40px;
			line-height: 40px;
			text-align: center;
			border-radius:50%;
			background-color:$primary-color;
			color: $white-color;
			z-index: 999999;
			opacity: 1;
			font-weight: 400;
		}
	}
	.modal-dialog{
		width: auto;
		margin-top: 155px;
		@include mobile{
			width: 400px;
			margin-left: auto;
			margin-right: auto;
		}
		@include tablet{
			width: 650px;
		}
		@include desktops {
			width: 770px;
		}
	}
	.formPanel.panel{
		margin-bottom: 0;
	}
}
/*=== 2.9 MEDIA ===*/
.media{
	.media-left{
		width: 100%;
		display: block;
		padding-right:0;
		position:relative;
		margin-bottom: 20px;
		@include tablet {
			padding-right:15px;
			display: table-cell;
    		vertical-align: top;
		}
		@include desktops {
			padding-right:25px;
		}
		img{
			display: inline-block;
			max-width: 100%;
			position:relative;
			width: 100%;
		}
	}
	.media-body{
		display: block;
		width: 100%;
		@include tablet {
			width: 10000px;
			display: table-cell;
    		vertical-align: top;
		}
	}
	.media-heading {
	    margin-bottom: 15px;
	    @include desktops {
			margin-bottom: 25px;
		}
	}
	.list-inline{
		margin-bottom: 20px;
		li{
			font-size: 13px;
			i{
				margin-right: 8px;
			}
			a{
				color: #666666;
			}
		}
	}
	p{
		margin-bottom: 0;
	}
}
.listView{
	margin-top:25px;
	padding-bottom:25px;
	&:last-child{
		padding-bottom:0;
	}
	.media-left{
	    @include tablet {
			width: 30%;
		}
		@include desktops {
			 width: 32%;
		}
	}
	.media-heading {
	    @include desktops {
			margin-bottom: 20px;
		}
	}
}
.common-media{
	margin-top:50px;
	@include desktops {
		margin-top:100px;
	}
	.media-left{
		@include tablet {
			width: 45%;
		}
		@include desktops {
			width: 35%;
		}
	}
}
.sticker{
	width:80px;
	height:35px;
	text-align:center;
	display:inline-block;
	height:35px;
	font-size:18px;
	font-weight:700;
	color:$white-color;
	background-color:$primary-color;
	position:absolute;
	top:0;
	left:0;
	@include mobile {
		width:100px;
		height:45px;
		line-height:45px;
		font-size:22px;
	}
	@include tablet {
		width:80px;
		height:35px;
		height:35px;
		font-size:18px;
	}
	@include desktops {
		width:100px;
		height:45px;
		line-height:45px;
		font-size:22px;
	}
}
/*=== 2.13 PAGER ===*/
.pagerArea{
	>.pager{
		margin: 80px 0 250px 0;
		li{
			padding: 0 15px;
			a{
				width: 114px;
				height: 45px;
				padding: 0 20px;
				border: none;
				line-height: 45px;
				@include box-shadow(0, 4px, 0, rgba($black-color, .1) );
				background-color: $primary-color;
				@include border-radius(6px);
				font-size: 16px;
				text-transform: uppercase;
				color: $white-color;
				@include transition(all 0.3s ease-in-out);
				font-weight: 600;
			}
			&:first-child{
				a{
					i{
						margin-right: 10px;
					}
				}
			}
			&:last-child{
				a{
					i{
						margin-left: 10px;
					}
				}
			}
		}
	}
}
/*=== 2.13 PAGINATION ===*/

.pagination {
  display: inline-block;
  padding-left: 0;
  margin:0;
  border-radius:0;

  > li {
    display: inline-block;
    margin-right: 3px;
    > a,
    > span {
      font-size: 12px;
      font-weight: 700;
      text-transform: uppercase;
      position: relative;
      float: left;
      padding: 9px 16px;
      line-height: 1.42857143;
      text-decoration: none;
      color: $text-color;
      background-color:transparent;
      border: 1px solid #dedede;
      margin-left: -1px;
    }
    &:first-child {
      > a,
      > span {
        margin-left: 0;
        border-radius:0;
      }
    }
    &:last-child {
      > a,
      > span {
        border-radius:0;
      }
    }
  }
  > li > a,
  > li > span {
    &:hover,
    &:focus {
      z-index: 2;
      color:$white-color;
      background-color:$primary-color;
      border-color: $primary-color;
    }
  }
  > .active > a,
  > .active > span {
    &,
    &:hover,
    &:focus {
      z-index: 3;
      color:$white-color;
      background-color:$primary-color;
      border-color: $primary-color;
      cursor: default;
    }
  }

  > .disabled {
    > span,
    > span:hover,
    > span:focus,
    > a,
    > a:hover,
    > a:focus {
      color: rgba($text-color,0.5);
      background-color:transparent;
      border: 1px solid #dedede;
      cursor: disabled;
    }
  }
}
