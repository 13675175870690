.attorneys-profile img{
	margin-bottom: 30px;
    @include tablet {
        margin-bottom: 0;
    }
}
.team-rank{
	margin-bottom:15px;
}
.profile-content{
	h2{
		margin-bottom: 10px;
	}
}
.profile-contact{
	font-size:13px;
	line-height:23px;
	address{
		margin-bottom: 15px;
	}
	span{
		display: block;
		i{
			color: $primary-color;
			margin-right: 10px;
		}
	}
	a{
		color: $text-color;
		text-decoration: underline;
	}
	.teamContent{
		margin-top: 15px;
	}
}
.profile-progress{
	.progress-wrapper {
		padding: 0;
		margin-top: 40px;
	    background-color:transparent;
	    @include tablet {
	        padding: 0 0 0 30px;
	    	border-left:1px solid #dedede;
	    	margin-top: 0;
	    }
	    @include desktops {
	        padding: 0 0 0 50px;
	    	margin-left: 10px;
	    }
	    @include large-desktops {
	        padding: 0 0 0 80px;
	    	margin-left: 20px;
	    }
	}
}
.profile-detail{
	h4{
		font-size: 17px;
		margin-bottom: 10px;
		span{
			color: $primary-color;
		}
	}
}