.testimonial-grid{
	.testimonials{
		margin-bottom: 40px;;
		@include tablet {
	        margin-bottom: 0;
	    }
		@include desktops {
	       padding:0 30px;
	    }
	}
	.col-xs-12:last-child .testimonials{
		margin-bottom: 0;
	}
}