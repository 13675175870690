/*=== MIXINS ===*/
@mixin box-sizing($box) {
  -webkit-box-sizing: $box;
  -moz-box-sizing: $box;
  box-sizing: $box;
}
@mixin border-radius($radius: 4px) {
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    border-radius: $radius;
}
@mixin box-shadow($horizontal: 0px, $vertical: 1px, $blur: 2px, $color: #CCC) {
  -webkit-box-shadow: $horizontal $vertical $blur $color;
  -moz-box-shadow: $horizontal $vertical $blur $color;
  box-shadow: $horizontal $vertical $blur $color;
}
@mixin opacity ( $value: 0.5 ) {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$value)";
  filter: alpha(opacity=$value);
  -moz-opacity: $value;
  -khtml-opacity: $value; 
  opacity: $value;
}
@mixin transition($args...) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}
@mixin transform($transforms) {
  -moz-transform: $transforms;
  -o-transform: $transforms;
  -ms-transform: $transforms;
  -webkit-transform: $transforms;
  transform: $transforms;
}
