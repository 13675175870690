.more{
	font-size: 12px;
	font-weight: 700;
	color: $text-color;
	text-transform: uppercase;
	&:hover{
		color: $primary-color;
	}
}
.blog{
	.primary-content .col-xs-12{
		margin-bottom: 60px;
		@include tablet {
	        margin-bottom: 100px;
	    }
	}
}
.blog-grid{
	.thumbnail{
		padding: 0;
		border:none;
		margin-bottom: 0;
		a>img, >img{
			width: 100%;
		}
	}
	.thumbnail .caption {
	    padding: 0;
	}
}
.thumbnail.blog-details,
.comments{
	margin-bottom:100px;
	padding-bottom:70px;
	border-bottom:1px solid #dedede;
}
.blog-list .listView{
	margin-top:0;
	padding-bottom:60px;
	@include tablet {
		padding-bottom:100px;
	}
	.media-left{
	    @include tablet {
			width: 32%;
		}
		@include desktops {
			 width: 35%;
		}
	}
	p{
		margin-bottom: 20px;
	}
}
.thumbnail{
	blockquote{
		padding:35px 25px;
		background: #f5f5f5;
		border:none;
		margin: 0 0 25px;
    	font-size: 13px;
    	a{
    		color: $primary-color;
    	}
	}
}
.tag{
	span{
		font-family: 'Libre Baskerville', serif;
		text-transform: uppercase;
		font-size: 15px;
		font-weight: 700;
		color: $title-color;
		display: block;
		a{
			color: $title-color;
			text-transform: capitalize;
		}
	}
}
.media-list.comment-list{
	.media .media-left {
		width: auto;
	    float: left;
	    padding-right:15px;
	    display: table-cell;
    	vertical-align: top;
	    img{
	    	width: 70px;
	    	height: 70px;
	    }
	}
	.media-heading{
		margin-bottom: 15px;
	}
	.media-body{
		width: 10000px;
	    display: table-cell;
	    vertical-align: top;
		span{
			font-size: 12px;
			color: rgba($text-color,0.5);
			display: block;
			margin-bottom: 20px;
			i{
				margin-right: 10px;
			}
		}
		a{
			font-size: 12px;
			color: $primary-color;
			font-weight: 700;
			display: block;
			margin-bottom: 30px;
		}
		p{
			margin-bottom: 20px;
		}
	}
	>.media{
		border-top:1px solid #dedede;
		padding-top: 30px;
		margin: 0;
		.media{
			border-top:1px solid #dedede;
			padding-top: 30px;
		}
		&:first-child{
			border-top:none;
			padding-top: 0;
		}
	}
}
.contact-form.comments-form .formField textarea.form-control {
    height: 170px;
}	
