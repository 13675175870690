.contact-info{
	padding-bottom:80px;
	.box-inner{
		i{
			color: $primary-color;
		}
		p{
			margin-bottom: 0;
			height: auto;
		}
		a{
			color: $text-color;
			text-decoration: underline;
			&:hover{
				color: $primary-color;
			}
		}
	}
	.col-sm-4:nth-child(2){
		margin-top: 30px;
		margin-bottom: 30px;
		@include tablet {
			margin-top: 0;
			margin-bottom: 0;
		}
	}
}
.contact-form{
	.formField textarea.form-control {
		@include tablet {
	        height:230px;
	    }
	}
	.formField {
	    margin-bottom: 15px;
	    @include tablet {
	        margin-bottom: 30px;
	    }
	    &:last-child{
	    	margin-bottom: 0;
	    }
	}
	.formField .btn-secondary {
	    margin-top: 20px;
	}
	.panel {
	    margin-bottom: 0;
	    background-color: #fff;
	    border-radius: 0;
	    box-shadow:none;
	}
}
.mapArea{
	width: 100%;
	display: block;
	#map {
		width: 100%;
		height: 420px;
	}
}
