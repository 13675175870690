/*=== 2.2 LIGHTER SECTION ===*/
.position{
    @include tablet {
		margin: 0 -5px;
		float: left;
	    width: 100%;
	}
	.position-box{
		padding: 25px 20px;
		border:1px solid #dedede;
		background: $white-color;
		@include box-shadow(0px, 1px, 1px, rgba($black-color,0.1));
		margin: 0 0 10px;
		width: 100%;
		@include tablet {
			width: 230px;
			float: left;
			margin: 0 5px 10px;
		}
		@include desktops {
			width: 303px;
		}
		@include large-desktops {
			width: 370px;
		}
		
		h3{
			margin-bottom: 5px;
			a{
				color: $title-color;
				&:hover{
					color: $primary-color;
				}
			}
		}
		p{
			margin-bottom: 0;
		}
	}
}
/*=== 2.2 WHITE SECTION ===*/
.detail-content{
	margin-bottom: 40px;
	@include tablet {
		margin-bottom: 0;
	}
	h2{
		margin-bottom: 10px;
	}
	h3{
		text-transform: capitalize;
	}
	ul li{
		font-size: 13px;
		display: block;
		margin-bottom: 8px;
		i{
			margin-right: 10px;
		}
	}
	.detail-left{
		border-bottom:1px solid #dedede;
		padding-bottom: 30px;
		margin-bottom: 30px;
		@include desktops {
			border-bottom:none;
			border-right:1px solid #dedede;
			margin-bottom: 0;
			padding-right:30px;
		}
	}
	.detail-right{
		@include desktops {
			padding-left:20px;
		}
	}
}
