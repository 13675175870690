/*=== 6.3 DARK SECTION ===*/
.paralax-info{
	margin-bottom:50px;
	@include tablet {
		margin-bottom:0;
	}
	@include large-desktops {
		padding:0 40px;
	}
	i{
		font-size: 40px;
		margin-bottom: 20px;
	    color: #dca44d;
	    @include mobile {
			font-size: 54px;
		}
		@include tablet {
			font-size: 40px;
		}
	    @include large-desktops {
			font-size: 54px;
		}
	}
	h4{
		color: #fefefe;
		margin-bottom: 10px;
		@include mobile {
		    margin-bottom: 20px;
		}
		@include tablet {
		    margin-bottom: 10px;
		}
	    @include large-desktops {
		    margin-bottom: 20px;
		}
	}
	p{
		color: rgba($white-color,0.7);
		font-size: 11px;
		line-height: 18px;
		margin-bottom: 15px;
		@include mobile {
			font-size: 13px;
			line-height: 23px;
			margin-bottom: 25px;
		}
		@include tablet {
		    font-size: 11px;
			line-height: 18px;
			margin-bottom: 15px;
		}
	    @include desktops {
		    font-size: 13px;
			line-height: 23px;
			margin-bottom: 25px;
		}
	}
	.btn{
		padding-left:25px;
		padding-right:25px;
	}
}
/*=== 6.3 WHITE SECTION ===*/
.para-inner{
	margin-bottom:50px;
	h3{
		text-transform: capitalize;
		@include desktops {
			margin-bottom: 30px;
		}
	}
	p{
		margin-bottom: 0;
	}
}
.video-content{
	img{
		max-width: 100%;
		display: inline-block;
	}
	 iframe{
		max-width:100%;
		border: none;
	}
}
.primary-content .navbar-collapse.content-collapse {
	margin-top: 0;
	margin-bottom:40px;
	@include tablet{
		margin-bottom:95px;
    }
	&:last-child{
		margin-bottom:0;
	}
}

/*=== 6.3 LIGHT SECTION ===*/
.lightSection{
	background-color:$primary-color;
	padding:75px 0;
}
.consultation{
	h2{
		font-size: 22px;
		margin-bottom: 10px;
		@include tablet{
	        font-size: 28px;
	        margin-bottom: 20px;
	    }
	}
	p{
		margin-bottom: 20px;
		width: auto;
		display: inline-block;
		@include tablet{
			width: 300px;
	        margin-bottom: 0;
	    }
	    @include desktops{
			width: 415px;
	    }
	}
	.input-group{
		@include tablet{
			float: right;
	    }
	    .form-control {
		    float: left;
		    width: 100%;
		    margin-bottom: 10px;
		    border: 2px solid $white-color;
		    background-color:transparent;
		    color: $white-color;
    		height: 41px;
    		border-radius:0;
    		@include mobile{
    			margin-bottom: 0;
				width: 265px;
				margin-right: 5px;
		    }
		}
		.form-control::-webkit-input-placeholder{
			color: $white-color;
			font-size: 13px;
			font-weight: 400;
		}
		.form-control::-moz-placeholder {
			color: $white-color;
			font-size: 13px;
			font-weight: 400;
		}
		.form-control:-ms-input-placeholder {
			color: $white-color;
			font-size: 13px;
			font-weight: 400;
		}
		.form-control:-moz-placeholder {
			color: $white-color;
			font-size: 13px;
			font-weight: 400;
		}
	}
	.btn-primary{
		background-color:$white-color;
		color: $text-color;
		padding:0 25px;
		height: 41px;
		line-height: 41px;
		width: auto;
		border: 1px solid $white-color;
		&:hover, &:focus{
			background-color:$white-color;
			border: 1px solid $white-color;
		}
	}
}
.consultation-btn{
	@include tablet{
		float: right;
		}
}
