/*=== 16. COMMING SOON ===*/
.commingSoon{
	text-align: center;
	background: #ffffff; 
}
.commingSoon .blackSection{
	padding: 50px 0;
	@include tablet{
		padding: 100px 0;
	}
	.pageLogo{
		height: auto;
		padding: 15px;
		width: 160px;
		position:relative;
		z-index: 1000;
		display: block;
		margin: 0 auto 20px;
		> img{
			width: 100%;
		}
		@include tablet{
			height: auto;
			padding: 30px 15px;
			width: 234px;
		}
	}
	h1{
		font-size: 20px;
		color: $title-color;
		text-transform: uppercase;
		margin: 0 auto;
		@include tablet{
			font-size: 28px;
		}
	}
	p{
		color: $text-color;
		display: block;
		font-size: 12px;
		margin: 10px 0 40px 0;
		@include tablet{
			font-size: 15px;
			margin: 10px 0 90px 0;
		}
	}
	.input-group{
		margin-bottom: 50px;
		width: 100%;
		.form-control{
			position:relative;
			background-color: transparent; 
			color: $text-color;
			height: 49px;
		    border-radius: 0;
		    border-color: #bfbfbf;
		    padding-left: 20px;
		    z-index: 1;
		}
		.input-group-addon{
			position:absolute;
			right: 10px;
			z-index: 9;
			top:10px;
		    background-color: transparent;
		    border-radius: 0;
		    border-color: transparent;
		    font-size: 18px;
		    color: $text-color;
		    padding: 6px 20px;
			i{
				font-size: 16px;
				color: #666666;
			}
		}
	}
	.list-inline{
		li{
			a{
				font-size: 18px;
				color: $text-color;
				padding: 0 7px;
				@include transition(all 0.3s ease-in-out);
			}
		}
	}
}
.count-down{
	padding: 0 0 75px 0;
	#simple_timer{
		width: 100%;
		margin: 0 auto;
		color: $white-color;
		text-align: center;
		.table-cell{
			display: inline-block;
			margin: 0 5px;
			@include mobile {
				margin: 0 10px;
			}
			@include large-desktops {
				margin: 0 12px;
			}
			.tab-val{
				font-weight: 700;
				display: block;
				margin-bottom: 25px;
				@include border-radius(100%);
				background-color: $secondary-color;
				width: 45px;
				height: 45px;
				line-height: 45px;
				font-size: 20px;
				@include mobile {
					width: 65px;
					height: 65px;
					line-height: 65px;
					font-size: 25px;
				}
				@include tablet {
					width: 85px;
					height: 85px;
					line-height: 85px;
					font-size: 35px;
				}
				@include large-desktops {
					width: 109px;
					height: 109px;
					line-height: 109px;
					font-size: 45px;
				}
			}
			.tab-metr{
				color: $text-color;
				font-size: 11px;
				font-weight: 700;
				text-transform: uppercase;
				@include tablet {
					font-size: 12px;
				}
			}
		}
	}
}