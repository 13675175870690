.block{
	margin-bottom:30px;
	>h3{
		margin: 0;
		padding:15px;
		background:$secondary-color;
		text-transform: capitalize;
		color: $white-color;
	}
	.block-inner{
		border:1px solid #dedede;
		padding:25px 15px;
	}
	h5{
		font-size: 15px;
		font-family: 'Montserrat', sans-serif;
		color: $title-color;
    	font-weight: 400;
	}
	span{
		display: block;
		margin-bottom: 25px;
	}
	.btn{
		padding-left:30px;
		padding-right:30px;
	}
}
.download{
	font-size: 12px;
	font-weight: 700;
	text-decoration: underline;
	color: $primary-color;
	display: block;
	margin-bottom: 25px;
	&:hover{
		color: $primary-color;
	}
}
.download-pdf{
	border:1px solid #dedede;
	font-size: 12px;
	font-weight: 700;
	color: $text-color;
	display: block;
	padding:12px 15px;
	text-transform:uppercase;
	&:hover{
		color: $text-color;
	}
	i{
		margin-right: 10px;
		font-size: 17px;
		vertical-align: initial;
	}
}
.block:last-child{
	margin-bottom:0;
}
.sidebar{
	.input-group{
		width: 100%;
	}
	.input-group .form-control {
	    position: relative;
	    z-index: 2;
	    float: none;
	    width: 100%;
	    margin-bottom: 0;
	    height: 45px;
	    border-radius:0;
	    border:1px solid #dedede;
	    padding: 6px 15px;
	    &:focus{
	    	border-color:$primary-color;
	    }
	}
	.input-group .btn {
	    padding: 0 5px;
	    position: absolute;
	    top: 0;
	    right: 0;
	    border: 0;
	    box-shadow: none;
	    z-index: 3;
	    line-height: 45px;
			width: 33px;
			outline: none;
			background: transparent;
	    i {
		    font-size: 16px;
		    color: #666666;
		}
	}
}
.form-control::-webkit-input-placeholder{
	color: $text-color;
	font-size: 13px;
	font-weight: 400;
}
.form-control::-moz-placeholder {
	color: $text-color;
	font-size: 13px;
	font-weight: 400;
}
.form-control:-ms-input-placeholder {
	color: $text-color;
	font-size: 13px;
	font-weight: 400;
}
.form-control:-moz-placeholder {
	color: $text-color;
	font-size: 13px;
	font-weight: 400;
}
.sideNav{
	li{
		border-bottom:1px solid #dedede;
		a{
			text-transform: capitalize;
			padding:10px 0;
			display: block;
			font-size: 13px;
			color: $text-color;
			&:hover{
				color: $primary-color;
			}
		}
	}
	li:last-child{
		border-bottom:none;
	}
}
.testimonials{
	img{
		margin:0 auto 30px;
	}
	i{
		font-size: 30px;
		color: $primary-color;
		margin-bottom: 20px;
	}
	p{
		font-size: 14px;
		line-height: 27px;
		color: rgba($text-color,0.6);
		margin-bottom: 15px;
	}
	h5{
		margin-bottom: 5px;
		a{
			font-size: 15px;
			color: $primary-color;
		}
	}
	span{
		font-size: 12px;
		color: rgba($text-color,0.6);
		display: block;
	}
}
.block{
	.owl-nav {
	    top: auto;
	    bottom:-10px;
	    margin-top: 0;
	    right: 0;
	    opacity: 1;
	    text-align: right;
	}
	.owl-controls .owl-nav .owl-prev,
	.owl-controls .owl-nav .owl-next{
	    padding: 0;
	    background-color: #dedede;
	    color: #666666;
	    border-radius: 0;
	    box-shadow: none;
	    width: 20px;
	    height: 20px;
	    text-align: center;
	    span{
	    	display:none;
		}
	}
	.owl-controls .owl-nav .owl-prev:after,
	.owl-controls .owl-nav .owl-next:after{
		display: inline-block;
	    font: normal normal normal 14px/1 FontAwesome;
	    font-size: inherit;
	    text-rendering: auto;
	    -webkit-font-smoothing: antialiased;
	    -moz-osx-font-smoothing: grayscale;
	    font-size: 11px;
	    color: $text-color;
	    width: 20px;
	    height: 20px;
	    line-height: 20px;
	}
	.owl-controls .owl-nav .owl-prev {
	    float: none;
	    left: auto;
	    margin-right: 4px;
	    &:after{
	    	content: "\f053";
	    }
	}
	.owl-controls .owl-nav .owl-next {
	    float: none;
	    right: auto;
	    &:after{
	    	content: "\f054";
	    }
	}
}
.block .media{
    padding-bottom: 20px;
	.media-left{
		width: 30%;
		padding-right:15px;
		display: table-cell;
		vertical-align: top;
		margin-bottom: 0;
	}
	.media-body{
		width: 10000px;
		display: table-cell;
		vertical-align: top;
	}
	.media-heading {
		font-size: 15px;
	    margin-bottom: 5px;
	}
	.list-inline{
		margin-bottom: 0;
		li{
			font-size: 12px;
			i{
				margin-right: 5px;
			}
			a{
				color: #666666;
			}
		}
	}
}
