/*=== VARIABLES ===*/
$primary-color:#dca44d;
$secondary-color:#515b78;
$white-color: #ffffff;
$black-color: #000000;
$text-color:#666666;
$title-color:#222222;

@function tint($primary-color, $percentage) {
  @return mix(white, $primary-color, $percentage);
}
