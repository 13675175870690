.price-table .col-xs-12{
	margin-bottom:50px;
	&:last-child{
		margin-bottom: 0;
	}
	@include tablet{
		margin-bottom: 0;
	}
}
.priceUper{
	color: $white-color;
	padding:25px 0;
	background:$secondary-color;
	@include transition(all 0.3s ease-in-out);
	h5{
		font-size: 15px;
		color: $white-color;
		text-transform: uppercase;
		margin-bottom: 15px;
		 @include tablet{
			font-size: 12px;
		}
		@include desktops{
			margin-bottom: 25px;
			font-size: 15px;
		}
	}
	h2{
		font-family: 'Montserrat', sans-serif;
	    font-size: 50px;
	    line-height: 50px;
	    color: $white-color;
	    font-weight: 400;
	    margin: 0;
	    @include mobile{
			font-size: 70px;
	    	line-height: 70px;
		}
	    @include tablet{
			font-size: 50px;
	    	line-height: 50px;
		}
		@include desktops{
			font-size: 70px;
	    	line-height: 70px;
		}
	}
	span{
		font-size: 16px;
		display: block;
		margin-bottom: 25px;
	}
	.btn{
		padding-left:30px;
		padding-right:30px;
		&.btn-border:hover{
			background:$white-color;
			color: $text-color;
			border-color: $white-color;
		}
	}
	&:hover{
		@include transition(all 0.3s ease-in-out);
		background:$primary-color;
	}
}
.price-tranc{
	.priceUper{
		border:1px solid #dedede;
		background:#f5f5f5;
		color: $title-color;
		h5{
			color: $title-color;
		}
		h2{
			color: $title-color;
		}
	}
}
.priceLower{
	padding:20px 10px;
	@include mobile{
		padding:20px 40px;
	}
    @include tablet{
		padding:20px 10px;
	}
	@include desktops{
		padding:20px;
	}
	&.price-3col{
		@include large-desktops{
			padding:20px 70px;
		}
	}
	border-top:none;
	li{
		font-size: 12px;
		padding:8px 10px;
		@include mobile{
			padding:12px 30px;
		}
		@include tablet{
			font-size: 14px;
			padding:8px 10px;
		}
		@include desktops{
			padding:12px 30px;
		}
		border-bottom:1px solid #dedede;
		&:last-child{
			border-bottom:none;
		}
	}
}