.errorMsg {
	@include tablet {
		text-align: right;
		padding-right:50px;
		margin-right:20px;
		border-right:1px solid #dedede;
	}
	h1{
		font-size: 100px;
		line-height: 100px;
		color: $primary-color;
		margin:0;
		font-weight: 400;
	    display: inline-block;
	    @include tablet {
			font-size: 200px;
			line-height: 200px;
		}
	}
}
.errorInfo{
	h3{
		font-family: 'Montserrat', sans-serif;
		font-size: 25px;
		font-weight:400;
		margin-bottom: 15px;
		text-transform: capitalize;
		@include tablet {
			font-size: 30px;
			margin-bottom: 25px;
		}
	}
	.input-group{
		margin-bottom: 30px;
		width: 100%;
		.form-control{
			position:relative;
			background-color: transparent; 
			color: $text-color;
			height: 49px;
		    border-radius: 0;
		    border-color: #bfbfbf;
		    padding-left: 20px;
		    z-index: 1;
		}
		.input-group-addon{
			position:absolute;
			right: 10px;
			z-index: 9;
			top:10px;
		    background-color: transparent;
		    border-radius: 0;
		    border-color: transparent;
		    font-size: 18px;
		    color: $text-color;
		    padding: 6px 20px;
			i{
				font-size: 16px;
				color: #666666;
			}
		}
	}
	.btn{
		padding-left:25px;
		padding-right:25px;
	}
}